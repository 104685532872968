@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: block;
  width: 100vw;
  height: 100vh;
  min-width: 1440px;
  min-height: 900px;
  background-color: #1D1E1F;
}

.App {
  text-align: center;
  background-color: black;
}
.EditorPageContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;
}

.EditorPage {
  display: block;
  width: 1440px;
  height: 900px;
  overflow: hidden;
}

.App {
  text-align: center;
  background-color: black;
}

#popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

#dialogContent {
  margin: 15% auto;
  background-color: rgba(210, 212, 217, 1.0);
  width: 830px;
  height: 370px;
}

#dialogContent>svg {
  margin-left: 140px;
  margin-top: 70px;
}

#buttons {
  margin-left: 163px;
  margin-top: 39px;
  display: grid;
  grid-template-columns: 303px 303px;
}

#buttons>svg {
  cursor: pointer;
}
.header {
  width: 1440px;
  height: 40px;
}
#editor-main {
  background-color: #323335;
  display: grid;
  grid-template-columns: 220px 910px 300px;
  color: #FFFFFF;
  height: 600px;
}

#editor-main #main {
  position: relative;
  width: 200px;
  padding: 10px;
  text-align: left;
  margin-top: 50px;
  text-align: center;
}

.timeline {
  position: relative;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  list-style-type: none;
}

.direction-r:before {
  position: absolute;
  left: 10%;
  top: 0;
  content: ' ';
  display: block;
  width: 2px;
  height: 68%;
  margin-left: -1px;
  background: white;
}

.direction-r.last-element:before {
  width: 0px;
}

.timeline li {
  padding: 2em 0;
}

.timeline .hexa {
  width: 10px;
  height: 10px;
  position: absolute;
  background: white;
  z-index: 5;
  left: -160px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -33px;
  margin-top: 0;
  border-radius: 20px;
}

.timeline .hexa:before {
  border-bottom: 4px solid #6B7177;
  border-left-width: 4px;
  border-right-width: 4px;
  top: -4px;
}

.timeline .hexa:after {
  border-left-width: 4px;
  border-right-width: 4px;
  border-top: 4px solid #6B7177;
  bottom: -4px;
}

.direction-r {
  float: none;
  width: 100%;
  text-align: center;
  color: #6B7177;
}

.direction-r.current {
  color: white;
}

.direction-r object {
  margin-left: 40px;
  margin-top: -28px;
  margin-bottom: 30px;
}

.flag-wrapper {
  text-align: center;
  position: relative;
}

.flag {
  position: relative;
  display: inline;
  font-weight: 600;
  z-index: 15;
  padding: 6px 10px;
  text-align: left;
  border-radius: 5px;
  left: -30px;
  top: -40px;
  color: #6B7177;
}

.baloom {
  background-color: white;
  color: #323335;
}

.desc {
  position: relative;
  margin: 1em 0 0 0;
  padding: 1em;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
  z-index: 15;
}

.desc p {
  margin: 0;
}

.direction-r .desc {
  position: relative;
  left: -14px;
  top: -60px;
  font-size: 18px;
}

#helpButton .tooltiptext {
  display: none;
  width: 80px;
  height: 24px;
  font-size: 11px;
  line-height: 2;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -40px;
  opacity: 0;
  transition: opacity 0.3s;
}

#helpButton .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

#helpButton:hover .tooltiptext {
  display: block;
  opacity: 1;
}

#effect-pane {
    background-color: #474A4E;
    margin-left: 10px;
    width: 294px;
    height: 578px;
}

#effect-preview {
    margin: 12px 0 0 12px;
    width: 271px;
    height: 155px;
    background-color: #BDC2C6;
}

#effect-preview object {
    background-color: #BDC2C6;
    width: 271px;
    height: 155px;
}

#effect-preview iframe {
    background-color: #BDC2C6;
    width: 1900px;
    height: 1080px;
    -webkit-transform: scale(0.142);
            transform: scale(0.142);
    -webkit-transform-origin: left top;
            transform-origin: left top;
}

.effect-title {
    background-color: #000000;
    text-align: left;
    margin-top: 6px;
    display: grid;
    grid-template-columns: 43px auto;
}

.effect-title img {
    margin-right: 8px;
}

.effect-title span {
    font-size: 14px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}

.effect-row {
    display: grid;
    grid-template-columns: 55px 55px 55px 55px 55px;
    padding-left: 12px;
}

.effect-item {
    cursor: pointer;
    width: 47px;
    height: 47px;
    background-color: #303233;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    margin: 6px 4px 0 4px;
    line-height: 24px;
    font-weight: bold;
}

.effect-item p.two-line {
    margin-top: 6px;
    font-size: 10px;
    line-height: 16px;
}

.effect-item p.small-elem {
    font-size: 11px;
}

.effect-row.sound {
    display: grid;
    grid-template-columns: 47px 47px 47px 47px 47px 47px;
    margin-top: 10px;
    padding-left: 8px;
}

.effect-item.sound {
    width: 40px;
    height: 40px;
    margin: 6px 2px 0 2px;
    line-height: 40px;
    font-size: 20px;
    font-weight: midium;
}

.effect-item span {
    pointer-events: none;
}

.effect-item p {
    pointer-events: none;
}

.effect-item img {
    pointer-events: none;
}

#sound {
    visibility: hidden;
    height: 0px;
}

#helpButton {
    position: absolute;
    left: 52px;
    top: 472px;
}

#helpButton img:hover {
    cursor: pointer;
}

.effect-item {
    position: relative;
    display: inline-block;
}

.effect-item .tooltiptext {
    display: none;
    width: 80px;
    height: 24px;
    font-size: 11px;
    line-height: 2;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 90%;
    left: 50%;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
}

.effect-item .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.effect-item:hover .tooltiptext {
    display: block;
    opacity: 1;
}

.effect-item[data-ondrag='true'] .tooltiptext {
    display: none;
}
#preview-pane {
    background-color: #474A4E;
    height: 578px;
}

#anim-pane {
    margin: 10px;
    position: relative;
    pointer-events: none;
}

#anim-pane-preview {
    margin: 10px;
}

#anim-pane iframe,
#anim-pane-preview iframe {
    width: 1920px;
    height: 1080px;
    -webkit-transform: scale(.44);
            transform: scale(.44);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    position: absolute;
    top: 25px;
    left: 25px;
    border: 0;
    /* telop hack */
    border-bottom: 1px solid black;
}

#anim-pane-preview iframe {
    width: 1220px;
    height: 687px;
    left: 110px;
    top: 74px;
}

#control-pane {
    margin-top: 518px;
    padding: 10px 10px 10px 40px;
    width: 904px;
    display: grid;
    grid-template-columns: 50px 70px auto;
}

#control-pane img {
    cursor: pointer;
}

#control-pane div span:nth-child(1) {
    padding-right: 4px;
}

#control-pane div span:nth-child(2) {
    padding-left: 4px;
}

#audio-elems {
    display: none;
}
.AppFooter {
  background-color: black;
  height: 257px;
  display: grid;
  grid-template-columns:  1140px 300px;
}
#timeline-30-main {
    position: relative;
}

#timeline-30 {
    position: absolute;
    margin-top: 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-left: 32px;
}

#progressbar-30 {
    position: absolute;
    width: 15px;
    height: 230px;
    cursor: pointer;
    left: 251px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 19px;
}

#progressbar-30>div>p {
    margin-bottom: 0px;
    color: #DBC44E;
}

#progressbar-30>div>div {
    width: 2px;
    height: 193px;
    background-color: #DBC44E;
    left: 6px;
    position: absolute;
    margin-top: -4px;
}

.seek-row-30 {
    display: grid;
    grid-template-columns: 180px 917px;
}

.seek-row-30 .header-30 {
    width: 180px;
    height: 44px;
    display: grid;
    color: white;
    font-size: 1em;
    line-height: 8px;
    grid-template-columns: 30px 200px;
}

.seek-row-30:nth-child(n) .header-30 {
    background-color: #474A4E;
}

.seek-row-30:nth-child(2n+1) .header-30 {
    background-color: #6B7177;
}

.seek-row-30:first-child .header-30 {
    height: 30px;
    background-color: #000000;
}

.seek-row-30 .header-30 p:nth-child(2) {
    margin-left: 20px;
    text-align: left;
}

.seek-row-30 .body-30 {
    height: 44px;
    margin-left: 7px;
    position: relative;
}

.seek-row-30:first-child .body-30 {
    height: 30px;
    background-color: #000000;
}

.seek-row-30:nth-child(n) .body-30 {
    background-color: #474A4E;
}

.seek-row-30:nth-child(2n+1) .body-30 {
    background-color: #6B7177;
}

#video-seek-30 .header-30 {
    height: 65px;
}

#video-seek-30 .body-30 {
    height: 65px;
}

#video-seek-30 .body-30 {
    display: grid;
    grid-template-columns: 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px;
    padding-left: 38px;
}

#video-seek-30 .body-30 div:nth-child(2n) {
    background-color: #D2D4D9;
    height: 30px;
    margin-top: 17px
}

#video-seek-30 .body-30 div:nth-child(2n+1) {
    background-color: #BDC2C6;
    height: 30px;
    margin-top: 17px
}

#tick-30 .body-30 {
    display: grid;
    grid-template-columns: 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px;
    background-color: black;
    color: white;
    margin-left: 48px;
}

#tick-30 .body-30 div:nth-child(n):after {
    content: "|";
    display: block;
    font-size: 0.4em;
    margin-top: 4px;
    margin-left: 5px;
}

#tick-30 .body-30 div>p {
    top: 4px;
    margin-top: 22px;
}


#tick-30 .body-30 div {
    font-size: 12px;
    margin-left: -8px;
}

#visual-seek-30 .body-30 {
    width: 860px;
}

#visual-seek-30 {
    grid-template-columns: 180px 860px auto;
}

#visual-seek-30 .trash-space-30 {
    background-color: #6B7177;
}

#trash-30 {
    background-color: #303233;
    position: relative;
    left: 1084px;
    width: 40px;
    height: 110px;
    top: 120px;
    text-align: center;
}

#trash-30 img {
    margin-top: 40px;
}

#trash-30.hover {
    background-color: #303030;
}

#choose-pane-30 {
    position: absolute;
    margin-top: 46px;
    left: 247px;
    cursor: move;
}

#play-button-10s {
    cursor: pointer;
}

#stop-button-10s {
    cursor: pointer;
}


.header-30 .tooltiptext {
    display: none;
    width: 160px;
    height: 24px;
    font-size: 11px;
    line-height: 2;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 10px;
    margin-top: -38px;
}

.header-30 .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.header-30:hover .tooltiptext {
    display: block;
    opacity: 1;
}

#timeline-main {
    position: relative;
}

#timeline {
    position: absolute;
    margin-top: 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-left: 32px;
}

#progressbar {
    position: absolute;
    width: 15px;
    height: 230px;
    cursor: pointer;
    left: 251px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 19px;
    z-index: 99;
}

#progressbar>div>p {
    margin-bottom: 0px;
    color: #DBC44E;
}

#progressbar>div>div {
    width: 2px;
    height: 193px;
    background-color: #DBC44E;
    left: 6px;
    position: absolute;
    margin-top: -4px;
}

.seek-row {
    display: grid;
    grid-template-columns: 180px 917px;
}

.seek-row .header {
    width: 180px;
    height: 44px;
    display: grid;
    color: white;
    font-size: 1em;
    line-height: 8px;
    grid-template-columns: 30px 200px;
}

.seek-row:nth-child(n) .header {
    background-color: #474A4E;
}

.seek-row:nth-child(2n+1) .header {
    background-color: #6B7177;
}

.seek-row:first-child .header {
    height: 30px;
    background-color: #000000;
}

.seek-row .header p:nth-child(2) {
    margin-left: 20px;
    text-align: left;
}

.seek-row .body {
    height: 44px;
    margin-left: 7px;
    position: relative;
}

.seek-row:first-child .body {
    height: 30px;
    background-color: #000000;
}

.seek-row:nth-child(n) .body {
    background-color: #474A4E;
}

.seek-row:nth-child(2n+1) .body {
    background-color: #6B7177;
}

#video-seek .header {
    height: 65px;
}

#video-seek .body {
    height: 65px;
}

#video-seek .body {
    display: grid;
    grid-template-columns: 81px 81px 81px 81px 81px 81px 81px 81px 81px 81px;
    padding-left: 38px;
}

#video-seek .body div:nth-child(2n) {
    background-color: #D2D4D9;
    height: 30px;
    margin-top: 17px
}

#video-seek .body div:nth-child(2n+1) {
    background-color: #BDC2C6;
    height: 30px;
    margin-top: 17px
}

#tick .body {
    display: grid;
    grid-template-columns: 81px 81px 81px 81px 81px 81px 81px 81px 81px 81px 81px;
    background-color: black;
    color: white;
    margin-left: 48px;
}

#tick .body div:after {
    content: "|";
    display: block;
    font-size: 0.4em;
    margin-top: 4px;
    margin-left: 5px;
}


#tick .body div {
    font-size: 12px;
    margin-left: -8px;
}

#visual-seek .body {
    width: 860px;
}

#visual-seek {
    grid-template-columns: 180px 860px auto;
}

#visual-seek .trash-space {
    background-color: #6B7177;
}

.sound-indicator,
.visual-indicator,
.text-indicator {
    height: 30px;
    margin-top: 5px;
    position: absolute;
    cursor: pointer;
    left: 41px;
    z-index: 9;
}

.happy,
.party,
.star {
    width: 243px;
}

.heart {
    width: 405px;
}

#trash {
    background-color: #303233;
    position: relative;
    left: 1084px;
    width: 40px;
    height: 110px;
    top: 120px;
    text-align: center;
}

#trash img {
    margin-top: 40px;
}

#trash.hover {
    background-color: #DBC44E;
}

.header .tooltiptext {
    display: none;
    width: 160px;
    height: 24px;
    font-size: 11px;
    line-height: 2;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 10px;
    margin-top: -38px;
}

.header .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.header:hover .tooltiptext {
    display: block;
    opacity: 1;
}

#screen-movement-main {
    margin-top: 76px;
    margin-left: auto;
    margin-right: auto;
}

#screen-movement-main img:nth-child(2) {
    margin-top: 14px;
}

#screen-movement-main img {
    margin-top: 10px;
}

#screen-movement-main img.button-step2 {
    display: block;
    margin: 0 auto 20px;
}

#screen-movement-main img:hover {
    cursor: pointer;
}

#screen-movement-main-choice {
    margin-top: 76px;
    margin-left: auto;
    margin-right: auto;
}

#screen-movement-main-choice img {
    margin-top: 90px;
}

#screen-movement-main-choice>img:hover {
    cursor: pointer;
}
#zvCursor {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 42px;
  height: 42px;
  border-radius: 42px;
  background-color: #00DFFF;
  box-sizing: border-box;
  border: 5px solid white;
  z-index: 99;
}
.Login {
  height: 100vh;
  background-color: #D6D7DB;
}

.Login header {
  background-color: white;
  height: 180px;
}

#header_logo {
  padding-top: 42px;
  padding-left: 70px;
  width: 411px;
}

#login-content {
  width: 100vw;
  height: calc(100vh - 193.2px);
  text-align: center;
}

#announcement {
  padding-top: 80px;
}

#input_content {
  margin-top: 42px;
}

#input_content input {
  text-align: center;
  width: 400px;
  height: 80px;
  font-size: 40px;
}

#alert {
  display: block;
  min-height: 120px;
  max-height: 920px;
  padding: 20px 0 25px;
}

#alert .error {
  display: block;
  margin: 10px auto;
}


#disable-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.8;
  display: none;
}

#progress-dialog-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: none;
}

#progress-dialog {
  width: 500px;
  height: 230px;
  background-color: #D2D4D9;
  margin: 357px auto auto auto;
  opacity: 1.0;
  text-align: center;
}

#progress-dialog img:first-child {
  width: 72px;
  margin: 32px;
}

.clickable {
  cursor: pointer;
}

.recovery-button {
  margin-top: -20px;
}

#preview {
  width: 100vw;
  height: 100vh;
  background: #1D1E1F;
}

.tutorial {
  height: 100vh;
}

.tutorial-svg {
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  max-width: 100vw;
  max-height: 100vh;
}

.tutorial-svg:first-child {
  visibility: visible;
}
.App {
  text-align: center;
  background-color: black;
}



.choose-secene-container {
  /* centoring inner */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;

  background-color: #E5E5E5;
}

.choose-scene-main {
  display: block;
  padding: 62px 80px;
  width: 1108px;
  height: 740px;

}

#scene {
  display: grid;
  grid-template-columns: 440px 440px 440px;
  margin-top: 64px;
}

#scene div {
  margin-bottom: 65px;
}
.PreviewPage-container {
  /* centoring inner */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;

  background-color: #1D1E1F;
}

.PreviewPage {
  display: block;
  position: relative;
  background-color: #474A4E;
  height: 816px;
  width: 1360px;
  /* margin: 42px 40px; */
}

#backToEdit {
  margin-top: 15px;
  margin-left: 75px;
}

#backToEdit img {
  cursor: pointer;
}

#play-button {
  position: absolute;
  top: 0px;
  margin-left: 76px;
  width: 1182px;
  text-align: center;
  cursor: pointer;
}

#play-button img {
  margin-top: 300px;
}
#player-pane {
    background-color: #474A4E;
    width: 1220px;
    height: 688px;
    margin: 32px 70px 0 0;
}

#player-anim-pane {
    margin: 10px;
    position: relative;
}

#player-anim-pane iframe {
    width: 1980px;
    height: 1090px;
    position: absolute;
    margin: 25px 0 0 65px;
    -webkit-transform: scale(.6161);
            transform: scale(.6161);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    border: 0;
}

#player-audio-elems {
    display: none;
}
.choose-bgm-container {
    /* centoring inner */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    height: 100vh;
    width: 100vw;
    min-width: 1440px;
    min-height: 900px;
}

.ChooseBGM {
    display: block;
    width: 1440px;
    height: 900px;
}

#screening-player-pane {
    background-color: #474A4E;
    height: 816px;
    width: 1360px;
    position: relative;
}

.ChooseBGM #splashSVG {
    margin-top: -35px;
    margin-left: -40px;
}

.ChooseBGM #screening-player-anim-pane {
    margin: 0;
    position: relative;
}

.ChooseBGM #screening-player-anim-pane div {
    width: 1220px;
    height: 685px;
    overflow: hidden;
    position: absolute;
    margin: 35px 0 0 70px;
}

.ChooseBGM #screening-player-anim-pane iframe {
    top: 0;
    left: 0;
    width: 1920px;
    height: 1080px;
    position: absolute;
    margin: 0;
    -webkit-transform: scale(.6354);
            transform: scale(.6354);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    border: 0;
}



#bgm-area {
    display: grid;
    grid-template-columns: 220px 220px 220px;

    position: absolute;
    top: 750px;
    left: 370px;
    width: 640px;
    height: 77px;
}
#screening-player-anim-pane {
    margin: 10px;
    position: relative;
}

#screening-player-anim-pane div {
    width: 1220px;
    height: 688px;
    position: absolute;
    margin: 25px 0 0 65px;
}

#screening-player-anim-pane object {
    width: 1220px;
    height: 688px;
    position: absolute;
    top: 0;
    left: 0;
}

#splashSVG {
    position: relative;
    width: 1440px;
    height: 900px;
    border: none;
}

#screening-player-anim-pane iframe {
    top: 0;
    left: 0;
    width: 1980px;
    height: 1090px;
    position: absolute;
    margin: 25px 0 0 65px;
    -webkit-transform: scale(.6161);
            transform: scale(.6161);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    border: 0;
}

#screening-player-audio-elems {
    display: none;
}
.ChooseBGMButton {
  width: 200px;
  height: 40px;
  border-bottom: 3px solid #6B7177;
}

.ChooseBGMButton.choose {
  border-bottom-color: #DBC44E;
}

.ChooseBGMButton>div {
  font-size: 25px;
  line-height: 36px;
  text-align: center;
}
.ScreeningPage-container {
  /* centoring inner */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;
}

.ScreeningPage {
  display: block;
  width: 1344px;
  height: 756px;
  overflow: hidden;
}

.ScreeningPage #splashSVG {
  display: block;
  width: 1440px;
  height: 900px;
  -webkit-transform: scale(0.9333);
          transform: scale(0.9333);
  -webkit-transform-origin: left top;
          transform-origin: left top;
}


.ScreeningPage #screening-player-pane {
  display: block;
  width: 1344px;
  height: 756px;
}

.ScreeningPage #screening-player-anim-pane {
  display: block;
  width: 1344px;
  height: 756px;
  margin: 0;
}


.ScreeningPage #screening-player-anim-pane div {
  width: 1344px;
  height: 756px;
  position: absolute;
  margin: 0;
}

.ScreeningPage #screening-player-anim-pane object {
  width: 1344px;
  height: 756px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

.ScreeningPage #screening-player-anim-pane iframe {
  top: 0;
  left: 0;
  margin: 0;
  width: 1920px;
  height: 1080px;
  position: absolute;
  -webkit-transform: scale(.7);
          transform: scale(.7);
  -webkit-transform-origin: left top;
          transform-origin: left top;
  border: 0;
}

.ScreeningPage #screening-player-anim-pane #loading {
  width: 1344px;
  height: 756px;
}

.ScreeningPage #screening-player-anim-pane #loading img {
  width: 1344px;
  height: 756px;
}


#screening-player-audio-elems {
  display: none;
}
.ScreeningPage-container {
  /* centoring inner */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;
}

.ScreeningPage {
  display: block;
  width: 1344px;
  height: 756px;
  overflow: hidden;
}

.ScreeningPage #splashSVG {
  display: block;
  width: 1440px;
  height: 900px;
  -webkit-transform: scale(0.9333);
          transform: scale(0.9333);
  -webkit-transform-origin: left top;
          transform-origin: left top;
}


.ScreeningPage #screening-player-pane {
  display: block;
  width: 1344px;
  height: 756px;
}

.ScreeningPage #screening-player-anim-pane {
  display: block;
  width: 1344px;
  height: 756px;
  margin: 0;
}


.ScreeningPage #screening-player-anim-pane div {
  width: 1344px;
  height: 756px;
  position: absolute;
  margin: 0;
}

.ScreeningPage #screening-player-anim-pane object {
  width: 1344px;
  height: 756px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

.ScreeningPage #screening-player-anim-pane iframe {
  top: 0;
  left: 0;
  margin: 0;
  width: 1920px;
  height: 1080px;
  position: absolute;
  -webkit-transform: scale(.7);
          transform: scale(.7);
  -webkit-transform-origin: left top;
          transform-origin: left top;
  border: 0;
}

.ScreeningPage #screening-player-anim-pane #loading {
  width: 1344px;
  height: 756px;
}

.ScreeningPage #screening-player-anim-pane #loading img {
  width: 1344px;
  height: 756px;
}


#screening-player-audio-elems {
  display: none;
}
