.ScreeningPage-container {
  /* centoring inner */
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;
}

.ScreeningPage {
  display: block;
  width: 1344px;
  height: 756px;
  overflow: hidden;
}

.ScreeningPage #splashSVG {
  display: block;
  width: 1440px;
  height: 900px;
  transform: scale(0.9333);
  transform-origin: left top;
}


.ScreeningPage #screening-player-pane {
  display: block;
  width: 1344px;
  height: 756px;
}

.ScreeningPage #screening-player-anim-pane {
  display: block;
  width: 1344px;
  height: 756px;
  margin: 0;
}


.ScreeningPage #screening-player-anim-pane div {
  width: 1344px;
  height: 756px;
  position: absolute;
  margin: 0;
}

.ScreeningPage #screening-player-anim-pane object {
  width: 1344px;
  height: 756px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

.ScreeningPage #screening-player-anim-pane iframe {
  top: 0;
  left: 0;
  margin: 0;
  width: 1920px;
  height: 1080px;
  position: absolute;
  transform: scale(.7);
  transform-origin: left top;
  border: 0;
}

.ScreeningPage #screening-player-anim-pane #loading {
  width: 1344px;
  height: 756px;
}

.ScreeningPage #screening-player-anim-pane #loading img {
  width: 1344px;
  height: 756px;
}


#screening-player-audio-elems {
  display: none;
}