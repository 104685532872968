.choose-bgm-container {
    /* centoring inner */
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
    width: 100vw;
    min-width: 1440px;
    min-height: 900px;
}

.ChooseBGM {
    display: block;
    width: 1440px;
    height: 900px;
}

#screening-player-pane {
    background-color: #474A4E;
    height: 816px;
    width: 1360px;
    position: relative;
}

.ChooseBGM #splashSVG {
    margin-top: -35px;
    margin-left: -40px;
}

.ChooseBGM #screening-player-anim-pane {
    margin: 0;
    position: relative;
}

.ChooseBGM #screening-player-anim-pane div {
    width: 1220px;
    height: 685px;
    overflow: hidden;
    position: absolute;
    margin: 35px 0 0 70px;
}

.ChooseBGM #screening-player-anim-pane iframe {
    top: 0;
    left: 0;
    width: 1920px;
    height: 1080px;
    position: absolute;
    margin: 0;
    transform: scale(.6354);
    transform-origin: left top;
    border: 0;
}



#bgm-area {
    display: grid;
    grid-template-columns: 220px 220px 220px;

    position: absolute;
    top: 750px;
    left: 370px;
    width: 640px;
    height: 77px;
}