#screening-player-anim-pane {
    margin: 10px;
    position: relative;
}

#screening-player-anim-pane div {
    width: 1220px;
    height: 688px;
    position: absolute;
    margin: 25px 0 0 65px;
}

#screening-player-anim-pane object {
    width: 1220px;
    height: 688px;
    position: absolute;
    top: 0;
    left: 0;
}

#splashSVG {
    position: relative;
    width: 1440px;
    height: 900px;
    border: none;
}

#screening-player-anim-pane iframe {
    top: 0;
    left: 0;
    width: 1980px;
    height: 1090px;
    position: absolute;
    margin: 25px 0 0 65px;
    transform: scale(.6161);
    transform-origin: left top;
    border: 0;
}

#screening-player-audio-elems {
    display: none;
}