#timeline-30-main {
    position: relative;
}

#timeline-30 {
    position: absolute;
    margin-top: 10px;
    user-select: none;
    margin-left: 32px;
}

#progressbar-30 {
    position: absolute;
    width: 15px;
    height: 230px;
    cursor: pointer;
    left: 251px;
    user-select: none;
    margin-top: 19px;
}

#progressbar-30>div>p {
    margin-bottom: 0px;
    color: #DBC44E;
}

#progressbar-30>div>div {
    width: 2px;
    height: 193px;
    background-color: #DBC44E;
    left: 6px;
    position: absolute;
    margin-top: -4px;
}

.seek-row-30 {
    display: grid;
    grid-template-columns: 180px 917px;
}

.seek-row-30 .header-30 {
    width: 180px;
    height: 44px;
    display: grid;
    color: white;
    font-size: 1em;
    line-height: 8px;
    grid-template-columns: 30px 200px;
}

.seek-row-30:nth-child(n) .header-30 {
    background-color: #474A4E;
}

.seek-row-30:nth-child(2n+1) .header-30 {
    background-color: #6B7177;
}

.seek-row-30:first-child .header-30 {
    height: 30px;
    background-color: #000000;
}

.seek-row-30 .header-30 p:nth-child(2) {
    margin-left: 20px;
    text-align: left;
}

.seek-row-30 .body-30 {
    height: 44px;
    margin-left: 7px;
    position: relative;
}

.seek-row-30:first-child .body-30 {
    height: 30px;
    background-color: #000000;
}

.seek-row-30:nth-child(n) .body-30 {
    background-color: #474A4E;
}

.seek-row-30:nth-child(2n+1) .body-30 {
    background-color: #6B7177;
}

#video-seek-30 .header-30 {
    height: 65px;
}

#video-seek-30 .body-30 {
    height: 65px;
}

#video-seek-30 .body-30 {
    display: grid;
    grid-template-columns: 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px;
    padding-left: 38px;
}

#video-seek-30 .body-30 div:nth-child(2n) {
    background-color: #D2D4D9;
    height: 30px;
    margin-top: 17px
}

#video-seek-30 .body-30 div:nth-child(2n+1) {
    background-color: #BDC2C6;
    height: 30px;
    margin-top: 17px
}

#tick-30 .body-30 {
    display: grid;
    grid-template-columns: 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px 27px;
    background-color: black;
    color: white;
    margin-left: 48px;
}

#tick-30 .body-30 div:nth-child(n):after {
    content: "|";
    display: block;
    font-size: 0.4em;
    margin-top: 4px;
    margin-left: 5px;
}

#tick-30 .body-30 div>p {
    top: 4px;
    margin-top: 22px;
}


#tick-30 .body-30 div {
    font-size: 12px;
    margin-left: -8px;
}

#visual-seek-30 .body-30 {
    width: 860px;
}

#visual-seek-30 {
    grid-template-columns: 180px 860px auto;
}

#visual-seek-30 .trash-space-30 {
    background-color: #6B7177;
}

#trash-30 {
    background-color: #303233;
    position: relative;
    left: 1084px;
    width: 40px;
    height: 110px;
    top: 120px;
    text-align: center;
}

#trash-30 img {
    margin-top: 40px;
}

#trash-30.hover {
    background-color: #303030;
}

#choose-pane-30 {
    position: absolute;
    margin-top: 46px;
    left: 247px;
    cursor: move;
}

#play-button-10s {
    cursor: pointer;
}

#stop-button-10s {
    cursor: pointer;
}


.header-30 .tooltiptext {
    display: none;
    width: 160px;
    height: 24px;
    font-size: 11px;
    line-height: 2;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 10px;
    margin-top: -38px;
}

.header-30 .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.header-30:hover .tooltiptext {
    display: block;
    opacity: 1;
}
