#preview-pane {
    background-color: #474A4E;
    height: 578px;
}

#anim-pane {
    margin: 10px;
    position: relative;
    pointer-events: none;
}

#anim-pane-preview {
    margin: 10px;
}

#anim-pane iframe,
#anim-pane-preview iframe {
    width: 1920px;
    height: 1080px;
    transform: scale(.44);
    transform-origin: left top;
    position: absolute;
    top: 25px;
    left: 25px;
    border: 0;
    /* telop hack */
    border-bottom: 1px solid black;
}

#anim-pane-preview iframe {
    width: 1220px;
    height: 687px;
    left: 110px;
    top: 74px;
}

#control-pane {
    margin-top: 518px;
    padding: 10px 10px 10px 40px;
    width: 904px;
    display: grid;
    grid-template-columns: 50px 70px auto;
}

#control-pane img {
    cursor: pointer;
}

#control-pane div span:nth-child(1) {
    padding-right: 4px;
}

#control-pane div span:nth-child(2) {
    padding-left: 4px;
}

#audio-elems {
    display: none;
}