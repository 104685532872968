#effect-pane {
    background-color: #474A4E;
    margin-left: 10px;
    width: 294px;
    height: 578px;
}

#effect-preview {
    margin: 12px 0 0 12px;
    width: 271px;
    height: 155px;
    background-color: #BDC2C6;
}

#effect-preview object {
    background-color: #BDC2C6;
    width: 271px;
    height: 155px;
}

#effect-preview iframe {
    background-color: #BDC2C6;
    width: 1900px;
    height: 1080px;
    transform: scale(0.142);
    transform-origin: left top;
}

.effect-title {
    background-color: #000000;
    text-align: left;
    margin-top: 6px;
    display: grid;
    grid-template-columns: 43px auto;
}

.effect-title img {
    margin-right: 8px;
}

.effect-title span {
    font-size: 14px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}

.effect-row {
    display: grid;
    grid-template-columns: 55px 55px 55px 55px 55px;
    padding-left: 12px;
}

.effect-item {
    cursor: pointer;
    width: 47px;
    height: 47px;
    background-color: #303233;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    margin: 6px 4px 0 4px;
    line-height: 24px;
    font-weight: bold;
}

.effect-item p.two-line {
    margin-top: 6px;
    font-size: 10px;
    line-height: 16px;
}

.effect-item p.small-elem {
    font-size: 11px;
}

.effect-row.sound {
    display: grid;
    grid-template-columns: 47px 47px 47px 47px 47px 47px;
    margin-top: 10px;
    padding-left: 8px;
}

.effect-item.sound {
    width: 40px;
    height: 40px;
    margin: 6px 2px 0 2px;
    line-height: 40px;
    font-size: 20px;
    font-weight: midium;
}

.effect-item span {
    pointer-events: none;
}

.effect-item p {
    pointer-events: none;
}

.effect-item img {
    pointer-events: none;
}

#sound {
    visibility: hidden;
    height: 0px;
}

#helpButton {
    position: absolute;
    left: 52px;
    top: 472px;
}

#helpButton img:hover {
    cursor: pointer;
}

.effect-item {
    position: relative;
    display: inline-block;
}

.effect-item .tooltiptext {
    display: none;
    width: 80px;
    height: 24px;
    font-size: 11px;
    line-height: 2;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 90%;
    left: 50%;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
}

.effect-item .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.effect-item:hover .tooltiptext {
    display: block;
    opacity: 1;
}

.effect-item[data-ondrag='true'] .tooltiptext {
    display: none;
}