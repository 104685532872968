#editor-main {
  background-color: #323335;
  display: grid;
  grid-template-columns: 220px 910px 300px;
  color: #FFFFFF;
  height: 600px;
}

#editor-main #main {
  position: relative;
  width: 200px;
  padding: 10px;
  text-align: left;
  margin-top: 50px;
  text-align: center;
}

.timeline {
  position: relative;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  list-style-type: none;
}

.direction-r:before {
  position: absolute;
  left: 10%;
  top: 0;
  content: ' ';
  display: block;
  width: 2px;
  height: 68%;
  margin-left: -1px;
  background: white;
}

.direction-r.last-element:before {
  width: 0px;
}

.timeline li {
  padding: 2em 0;
}

.timeline .hexa {
  width: 10px;
  height: 10px;
  position: absolute;
  background: white;
  z-index: 5;
  left: -160px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -33px;
  margin-top: 0;
  border-radius: 20px;
}

.timeline .hexa:before {
  border-bottom: 4px solid #6B7177;
  border-left-width: 4px;
  border-right-width: 4px;
  top: -4px;
}

.timeline .hexa:after {
  border-left-width: 4px;
  border-right-width: 4px;
  border-top: 4px solid #6B7177;
  bottom: -4px;
}

.direction-r {
  float: none;
  width: 100%;
  text-align: center;
  color: #6B7177;
}

.direction-r.current {
  color: white;
}

.direction-r object {
  margin-left: 40px;
  margin-top: -28px;
  margin-bottom: 30px;
}

.flag-wrapper {
  text-align: center;
  position: relative;
}

.flag {
  position: relative;
  display: inline;
  font-weight: 600;
  z-index: 15;
  padding: 6px 10px;
  text-align: left;
  border-radius: 5px;
  left: -30px;
  top: -40px;
  color: #6B7177;
}

.baloom {
  background-color: white;
  color: #323335;
}

.desc {
  position: relative;
  margin: 1em 0 0 0;
  padding: 1em;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
  z-index: 15;
}

.desc p {
  margin: 0;
}

.direction-r .desc {
  position: relative;
  left: -14px;
  top: -60px;
  font-size: 18px;
}

#helpButton .tooltiptext {
  display: none;
  width: 80px;
  height: 24px;
  font-size: 11px;
  line-height: 2;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  margin-left: -40px;
  opacity: 0;
  transition: opacity 0.3s;
}

#helpButton .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

#helpButton:hover .tooltiptext {
  display: block;
  opacity: 1;
}
