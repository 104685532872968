#preview {
  width: 100vw;
  height: 100vh;
  background: #1D1E1F;
}

.tutorial {
  height: 100vh;
}

.tutorial-svg {
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  max-width: 100vw;
  max-height: 100vh;
}

.tutorial-svg:first-child {
  visibility: visible;
}