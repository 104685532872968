#screen-movement-main {
    margin-top: 76px;
    margin-left: auto;
    margin-right: auto;
}

#screen-movement-main img:nth-child(2) {
    margin-top: 14px;
}

#screen-movement-main img {
    margin-top: 10px;
}

#screen-movement-main img.button-step2 {
    display: block;
    margin: 0 auto 20px;
}

#screen-movement-main img:hover {
    cursor: pointer;
}

#screen-movement-main-choice {
    margin-top: 76px;
    margin-left: auto;
    margin-right: auto;
}

#screen-movement-main-choice img {
    margin-top: 90px;
}

#screen-movement-main-choice>img:hover {
    cursor: pointer;
}