#timeline-main {
    position: relative;
}

#timeline {
    position: absolute;
    margin-top: 10px;
    user-select: none;
    margin-left: 32px;
}

#progressbar {
    position: absolute;
    width: 15px;
    height: 230px;
    cursor: pointer;
    left: 251px;
    user-select: none;
    margin-top: 19px;
    z-index: 99;
}

#progressbar>div>p {
    margin-bottom: 0px;
    color: #DBC44E;
}

#progressbar>div>div {
    width: 2px;
    height: 193px;
    background-color: #DBC44E;
    left: 6px;
    position: absolute;
    margin-top: -4px;
}

.seek-row {
    display: grid;
    grid-template-columns: 180px 917px;
}

.seek-row .header {
    width: 180px;
    height: 44px;
    display: grid;
    color: white;
    font-size: 1em;
    line-height: 8px;
    grid-template-columns: 30px 200px;
}

.seek-row:nth-child(n) .header {
    background-color: #474A4E;
}

.seek-row:nth-child(2n+1) .header {
    background-color: #6B7177;
}

.seek-row:first-child .header {
    height: 30px;
    background-color: #000000;
}

.seek-row .header p:nth-child(2) {
    margin-left: 20px;
    text-align: left;
}

.seek-row .body {
    height: 44px;
    margin-left: 7px;
    position: relative;
}

.seek-row:first-child .body {
    height: 30px;
    background-color: #000000;
}

.seek-row:nth-child(n) .body {
    background-color: #474A4E;
}

.seek-row:nth-child(2n+1) .body {
    background-color: #6B7177;
}

#video-seek .header {
    height: 65px;
}

#video-seek .body {
    height: 65px;
}

#video-seek .body {
    display: grid;
    grid-template-columns: 81px 81px 81px 81px 81px 81px 81px 81px 81px 81px;
    padding-left: 38px;
}

#video-seek .body div:nth-child(2n) {
    background-color: #D2D4D9;
    height: 30px;
    margin-top: 17px
}

#video-seek .body div:nth-child(2n+1) {
    background-color: #BDC2C6;
    height: 30px;
    margin-top: 17px
}

#tick .body {
    display: grid;
    grid-template-columns: 81px 81px 81px 81px 81px 81px 81px 81px 81px 81px 81px;
    background-color: black;
    color: white;
    margin-left: 48px;
}

#tick .body div:after {
    content: "|";
    display: block;
    font-size: 0.4em;
    margin-top: 4px;
    margin-left: 5px;
}


#tick .body div {
    font-size: 12px;
    margin-left: -8px;
}

#visual-seek .body {
    width: 860px;
}

#visual-seek {
    grid-template-columns: 180px 860px auto;
}

#visual-seek .trash-space {
    background-color: #6B7177;
}

.sound-indicator,
.visual-indicator,
.text-indicator {
    height: 30px;
    margin-top: 5px;
    position: absolute;
    cursor: pointer;
    left: 41px;
    z-index: 9;
}

.happy,
.party,
.star {
    width: 243px;
}

.heart {
    width: 405px;
}

#trash {
    background-color: #303233;
    position: relative;
    left: 1084px;
    width: 40px;
    height: 110px;
    top: 120px;
    text-align: center;
}

#trash img {
    margin-top: 40px;
}

#trash.hover {
    background-color: #DBC44E;
}

.header .tooltiptext {
    display: none;
    width: 160px;
    height: 24px;
    font-size: 11px;
    line-height: 2;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 10px;
    margin-top: -38px;
}

.header .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.header:hover .tooltiptext {
    display: block;
    opacity: 1;
}
