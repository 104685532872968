#root {
  display: block;
  width: 100vw;
  height: 100vh;
  min-width: 1440px;
  min-height: 900px;
  background-color: #1D1E1F;
}

.App {
  text-align: center;
  background-color: black;
}