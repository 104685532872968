.choose-secene-container {
  /* centoring inner */
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;

  background-color: #E5E5E5;
}

.choose-scene-main {
  display: block;
  padding: 62px 80px;
  width: 1108px;
  height: 740px;

}

#scene {
  display: grid;
  grid-template-columns: 440px 440px 440px;
  margin-top: 64px;
}

#scene div {
  margin-bottom: 65px;
}