.ChooseBGMButton {
  width: 200px;
  height: 40px;
  border-bottom: 3px solid #6B7177;
}

.ChooseBGMButton.choose {
  border-bottom-color: #DBC44E;
}

.ChooseBGMButton>div {
  font-size: 25px;
  line-height: 36px;
  text-align: center;
}