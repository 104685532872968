.Login {
  height: 100vh;
  background-color: #D6D7DB;
}

.Login header {
  background-color: white;
  height: 180px;
}

#header_logo {
  padding-top: 42px;
  padding-left: 70px;
  width: 411px;
}

#login-content {
  width: 100vw;
  height: calc(100vh - 193.2px);
  text-align: center;
}

#announcement {
  padding-top: 80px;
}

#input_content {
  margin-top: 42px;
}

#input_content input {
  text-align: center;
  width: 400px;
  height: 80px;
  font-size: 40px;
}

#alert {
  display: block;
  min-height: 120px;
  max-height: 920px;
  padding: 20px 0 25px;
}

#alert .error {
  display: block;
  margin: 10px auto;
}


#disable-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.8;
  display: none;
}

#progress-dialog-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: none;
}

#progress-dialog {
  width: 500px;
  height: 230px;
  background-color: #D2D4D9;
  margin: 357px auto auto auto;
  opacity: 1.0;
  text-align: center;
}

#progress-dialog img:first-child {
  width: 72px;
  margin: 32px;
}

.clickable {
  cursor: pointer;
}

.recovery-button {
  margin-top: -20px;
}
