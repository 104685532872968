#zvCursor {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 42px;
  height: 42px;
  border-radius: 42px;
  background-color: #00DFFF;
  box-sizing: border-box;
  border: 5px solid white;
  z-index: 99;
}