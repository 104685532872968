#player-pane {
    background-color: #474A4E;
    width: 1220px;
    height: 688px;
    margin: 32px 70px 0 0;
}

#player-anim-pane {
    margin: 10px;
    position: relative;
}

#player-anim-pane iframe {
    width: 1980px;
    height: 1090px;
    position: absolute;
    margin: 25px 0 0 65px;
    transform: scale(.6161);
    transform-origin: left top;
    border: 0;
}

#player-audio-elems {
    display: none;
}