.PreviewPage-container {
  /* centoring inner */
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;

  background-color: #1D1E1F;
}

.PreviewPage {
  display: block;
  position: relative;
  background-color: #474A4E;
  height: 816px;
  width: 1360px;
  /* margin: 42px 40px; */
}

#backToEdit {
  margin-top: 15px;
  margin-left: 75px;
}

#backToEdit img {
  cursor: pointer;
}

#play-button {
  position: absolute;
  top: 0px;
  margin-left: 76px;
  width: 1182px;
  text-align: center;
  cursor: pointer;
}

#play-button img {
  margin-top: 300px;
}