.EditorPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;
}

.EditorPage {
  display: block;
  width: 1440px;
  height: 900px;
  overflow: hidden;
}

.App {
  text-align: center;
  background-color: black;
}

#popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

#dialogContent {
  margin: 15% auto;
  background-color: rgba(210, 212, 217, 1.0);
  width: 830px;
  height: 370px;
}

#dialogContent>svg {
  margin-left: 140px;
  margin-top: 70px;
}

#buttons {
  margin-left: 163px;
  margin-top: 39px;
  display: grid;
  grid-template-columns: 303px 303px;
}

#buttons>svg {
  cursor: pointer;
}